<template>
  <div class="p-2">
    <div style="padding-top: 2.7rem">
      <div
        class="fixed-top d-flex justify-content-center border-bottom bg-white"
        style="opacity: 0.95"
      >
        <div class="col-12 p-0" style="max-width: 500px">
          <div
            class="d-flex align-items-center justify-content-between mt-2 mb-2 ml-3 mr-3"
          >
            <div style="width: 120px" class="d-flex">
              <div
                class="mr-auto d-flex align-items-center pt-1 pr-3"
                style="cursor: pointer"
                v-on:click="showCalendarModal"
              >
                <img
                  src="@/assets/image/icon/calendar.svg"
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
            <div>
              <b>{{ dateLabel }}</b>
            </div>
            <div style="width: 120px" class="d-flex">
              <div
                class="ml-auto d-flex align-items-center pt-1 pl-3"
                style="cursor: pointer"
                v-on:click="openCabinet"
              >
                <span
                  class="badge badge-pill badge-danger"
                  v-if="unreadMessageCount > 0"
                  >{{ unreadMessageCount }}</span
                >
                <img
                  src="@/assets/image/icon/user.svg"
                  width="24px"
                  height="24px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group mb-3">
        <button
          class="btn btn-primary btn-block btn-lg"
          v-on:click="addButtonClicked"
        >
          Добавить запись
        </button>
      </div>
      <Transition name="fade">
        <div key="if" id="products" v-if="loaded">
          <journal-payment
            v-if="paymentEnabled"
            v-bind:secondsBeforeExpiration="secondsBeforeExpiration"
            v-bind:paymentButtonLabel="paymentButtonLabel"
          ></journal-payment>
          <div
            key="onboarding-4"
            class="alert alert-warning alert-dismissible fade show mb-2"
            v-if="
              $store.state.onboardingIdList.includes(4) &&
              energyConsumedLimit === '0'
            "
          >
            У вас не установлена суточная норма калорий.
            <router-link to="/start/goal" class="alert-link"
              >Нажмите</router-link
            >, чтобы выполнить первоначальную настройку.
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="deleteOnboardingId(4)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            key="onboarding-2"
            class="alert alert-warning alert-dismissible fade show mb-2"
            v-else-if="
              hasEmail === false && $store.state.onboardingIdList.includes(2)
            "
          >
            Используется гостевой режим – записи и настройки не сохраняются.
            <router-link to="/account/sign-up?from=journal" class="alert-link"
              >Нажмите</router-link
            >, чтобы указать адрес электронной почты.
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="deleteOnboardingId(2)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            key="onboarding-3"
            class="alert alert-primary alert-dismissible fade show mb-2"
            v-else-if="
              $store.state.onboardingIdList.includes(3) && products.length > 0
            "
          >
            Для просмотра подробного отчёта нажмите на блок ниже.
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
              v-on:click="deleteOnboardingId(3)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <journal-nutrition-program
            v-if="nutritionProgramEnabled"
          ></journal-nutrition-program>
          <journal-summary
            v-bind:energyConsumedSum="energyConsumedSum"
            v-bind:energyConsumedLimit="energyConsumedLimit"
            v-bind:proteinPercent="proteinPercent"
            v-bind:fatTotalPercent="fatTotalPercent"
            v-bind:carbohydratesPercent="carbohydratesPercent"
            v-bind:proteinSum="proteinSum"
            v-bind:proteinLimit="proteinLimit"
            v-bind:fatTotalSum="fatTotalSum"
            v-bind:fatTotalLimit="fatTotalLimit"
            v-bind:carbohydratesSum="carbohydratesSum"
            v-bind:carbohydratesLimit="carbohydratesLimit"
          ></journal-summary>
          <div
            v-for="(list, index) in products"
            v-bind:key="`journal-product-list-${index}`"
          >
            <journal-product-list
              v-bind:list="list"
              v-bind:labelVisible="products.length > 1"
            ></journal-product-list>
          </div>
          <AdsBlock class="mt-2"></AdsBlock>
        </div>
        <div key="else" class="d-flex justify-content-center" v-else>
          <div class="spinner-border" role="status" v-if="loadSpinnerVisible">
            <span class="sr-only">Загрузка...</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import JournalPayment from "@/components/journal/Payment.vue";
import JournalNutritionProgram from "@/components/journal/NutritionProgram.vue";
import JournalSummary from "@/components/journal/Summary.vue";
import JournalProductList from "@/components/journal/ProductList.vue";
import { productMixin } from "@/mixins/product";
import { dateMixin } from "@/mixins/date";
import { onboardingMixin } from "@/mixins/onboarding.js";
import { apiMixin } from "@/mixins/api";
import Client from "@/api/Client";
import AdsBlock from "@/components/product/AdsBlock.vue";

export default {
  name: "JournalIndex",
  components: {
    AdsBlock,
    JournalPayment,
    JournalNutritionProgram,
    JournalSummary,
    JournalProductList,
  },
  mixins: [productMixin, dateMixin, onboardingMixin, apiMixin],
  data: function () {
    return {
      loaded: false,
      loadSpinnerVisible: false,
      products: [],
      nutritionProgramEnabled: false,
      paymentEnabled: false,
      secondsBeforeExpiration: 0,
      energyConsumedSum: 0,
      energyConsumedLimit: "0",
      hasEmail: null,
      unreadMessageCount: 0,
      paymentButtonLabel: null,
      proteinPercent: 0,
      fatTotalPercent: 0,
      carbohydratesPercent: 0,
      proteinSum: 0,
      proteinLimit: 0,
      fatTotalSum: 0,
      fatTotalLimit: 0,
      carbohydratesSum: 0,
      carbohydratesLimit: 0,
    };
  },
  computed: {
    date: function () {
      return this.$store.state.date;
    },
    dayOfWeek: function () {
      return this.getDayOfWeek(this.date);
    },
    dateLabel: function () {
      if (this.isDayAfterTomorrow) {
        return this.dayAfterTomorrow;
      }
      if (this.isTomorrow) {
        return this.tomorrowLabel;
      }
      if (this.isToday) {
        return this.todayLabel;
      }
      if (this.isYesterday) {
        return this.yesterdayLabel;
      }
      if (this.isDayBeforeYesterday) {
        return this.dayBeforeYesterdayLabel;
      }
      if (this.isThisYear) {
        return this.date.getDate() + " " + this.monthLabel;
      }
      return (
        this.date.getDate() +
        " " +
        this.monthLabel +
        " " +
        this.date.getFullYear()
      );
    },
    dayAfterTomorrow: function () {
      return "Послезавтра";
    },
    tomorrowLabel: function () {
      return "Завтра";
    },
    todayLabel: function () {
      return "Сегодня";
    },
    yesterdayLabel: function () {
      return "Вчера";
    },
    dayBeforeYesterdayLabel: function () {
      return "Позавчера";
    },
    monthLabel: function () {
      return this.$store.state.dateLabelMonths[this.date.getMonth()];
    },
    week: function () {
      return this.getWeek(this.date);
    },
    isThisYear: function () {
      return this.date.getFullYear() === new Date().getFullYear();
    },
    isDayAfterTomorrow: function () {
      let dayAfterTomorrow = new Date();
      dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
      return (
        this.getDateString(this.date) === this.getDateString(dayAfterTomorrow)
      );
    },
    isTomorrow: function () {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return this.getDateString(this.date) === this.getDateString(tomorrow);
    },
    isToday: function () {
      return this.getDateString(this.date) === this.getDateString(new Date());
    },
    isYesterday: function () {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return this.getDateString(this.date) === this.getDateString(yesterday);
    },
    isDayBeforeYesterday: function () {
      let dayBeforeYesterday = new Date();
      dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
      return (
        this.getDateString(this.date) === this.getDateString(dayBeforeYesterday)
      );
    },
  },
  methods: {
    searchButtonClicked: function () {
      this.addClick();
    },
    addButtonClicked: function () {
      this.addClick();
    },
    addClick: function () {
      this.$router.push({
        path: "/product/select",
        query: {
          date: this.getDateString(this.date),
        },
      });
    },
    openCabinet: function () {
      this.$router.push({
        path: "/account/cabinet",
      });
    },
    setDate: function (date) {
      this.$store.commit("changeDate", date);
      this.$router.push({
        path: "/journal",
        query: {
          date: this.getDateString(date),
        },
      });
      this.loadItems();
    },
    showCalendarModal: function () {
      this.$router.push({
        path: "/journal/calendar",
        query: {
          date: this.getDateString(this.date),
        },
      });
    },
    loadItems: function () {
      this.loadSpinnerVisible = false;
      let loadSpinnerTimer = setTimeout(() => {
        this.loadSpinnerVisible = true;
      }, 1000);
      this.loaded = false;
      let date = this.date;
      this.apiGet("/api/journal-api/load", {
        params: {
          date: this.getDateString(date),
          version: process.env.VUE_APP_VERSION,
        },
      })
        .then((response) => {
          if (date != this.date) {
            return;
          }
          if (response.data.status != "ok") {
            window.location.href = "/journal";
            return;
          }
          let productsGroupByTime = [];
          let lastProductTime = null;
          let foods = new Client().getFoodsFromResponse(response);
          foods.forEach(function (product) {
            if (
              lastProductTime === null ||
              lastProductTime - product.time >= 60
            ) {
              productsGroupByTime.push([]);
            }
            productsGroupByTime[productsGroupByTime.length - 1].push(product);
            lastProductTime = product.time;
          });
          this.products = productsGroupByTime;
          this.nutritionProgramEnabled = response.data.nutritionProgramEnabled;
          this.paymentEnabled = response.data.paymentEnabled;
          this.secondsBeforeExpiration = response.data.secondsBeforeExpiration;
          this.energyConsumedLimit = response.data.energyConsumedLimit;
          this.hasEmail = response.data.hasEmail;
          this.unreadMessageCount = response.data.unreadMessageCount;
          this.paymentButtonLabel = response.data.paymentButtonLabel;
          this.energyConsumedSum = response.data.energyConsumedSum;
          this.proteinPercent = response.data.proteinPercent;
          this.fatTotalPercent = response.data.fatTotalPercent;
          this.carbohydratesPercent = response.data.carbohydratesPercent;
          this.proteinSum = response.data.proteinSum;
          this.proteinLimit = response.data.proteinLimit;
          this.fatTotalSum = response.data.fatTotalSum;
          this.fatTotalLimit = response.data.fatTotalLimit;
          this.carbohydratesSum = response.data.carbohydratesSum;
          this.carbohydratesLimit = response.data.carbohydratesLimit;
          this.loaded = true;
          clearTimeout(loadSpinnerTimer);
        })
        .catch(() => {
          this.$router.push({
            path: "/offline",
          });
        });
    },
  },
  mounted: function () {
    let date = new Date(this.$route.query.date);
    if (!isNaN(date)) {
      this.$store.commit("changeDate", date);
    }
    this.loadItems();
    this.loadOnboardingIdList();
  },
};
</script>
